html {
  background-color: #282c34;
}

#root {
  background-color: #282c34;
  padding-top: 20px;
}

.container {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  width: 100%;
}

.photo {
  text-align: center;

}

.photo img {
  width: 90%;
}

.form {
  font-size: 14px;
  width: 100%;
  color: black
}


#propositions {
  list-style: none;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
}

#propositions li {
  display: block;
  text-align: center;
  min-width: 200px;
  width: 100%;
  padding: 10px;
  margin: 10px auto;
}

#propositions .mauvais {
  color: #950000;
  background-color: #FF5D5D;
  border: 1px solid #950000;
}

#propositions .bon {
  color: #00B500;
  background-color: #5EF35E;
  border: 1px solid #00B500;
}